'use client';
import { useState } from 'react';
import VerificationStep from './VerificationStep';
import LoginForm from './LoginForm';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

export default function AuthenticatorComponent({
  isSignupMode,
  emailFromCTA,
}: {
  isSignupMode?: boolean;
  emailFromCTA?: string;
}) {
  const [email, setEmail] = useState<string>(emailFromCTA || '');
  const [showVerificationStep, setShowVerificationStep] = useState<boolean>(emailFromCTA != null);
  const t = useTranslations('LogIn');

  return (
    <div className="flex flex-col items-center">
      {showVerificationStep ? (
        <VerificationStep email={email} />
      ) : (
        <LoginForm
          onSuccess={(email) => {
            setEmail(email);
            setShowVerificationStep(true);
          }}
        />
      )}

      <div>
        {isSignupMode ? (
          <>
            {t('Authenticator.YesAccount.Account')}
            <br />
            {t('Authenticator.YesAccount.instructions-1')}{' '}
            <Link href={`/login`}>{t('Authenticator.YesAccount.instructions-2')}</Link>{' '}
            {t('Authenticator.YesAccount.instructions-3')}
          </>
        ) : (
          <>
            {t('Authenticator.NoAccount.Account')}
            <br />
            {t('Authenticator.NoAccount.instructions-1')}{' '}
            <Link href={`/signup`}>{t('Authenticator.NoAccount.instructions-2')}</Link>{' '}
            {t('Authenticator.NoAccount.instructions-3')}
          </>
        )}
      </div>
    </div>
  );
}
