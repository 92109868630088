import React, { useState } from 'react';
import { useRouter } from 'next/navigation';

import OtpInput from 'react-otp-input';

import type { FormEvent } from 'react';
import { FormButton } from './FormButton';
import { useTranslations } from 'next-intl';

function VerificationStep({ email }: { email: string }) {
  const router = useRouter();
  const t = useTranslations('VerificationStep');

  const [otp, setOtp] = useState('');

  const [codeVerificationRequested, setCodeVerificationRequested] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<boolean>(false);

  async function handleCodeSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setCodeVerificationRequested(true);

    // request to the login api
    try {
      const url = `/api/auth/callback/email?email=${encodeURIComponent(email)}&token=${encodeURIComponent(otp)}&callbackUrl=/`;
      const req = await fetch(url);
      if (req.status === 200) {
        setCodeError(false);
        // redirect to homepage
        router.push(`/parents`);
        // reload the page so the session status updates accros all components
      } else {
        setCodeError(true);
        setCodeVerificationRequested(false);
      }
    } catch (err) {
      setCodeError(true);
      setCodeVerificationRequested(false);
    }
  }

  return (
    <>
      <form className="flex flex-col place-content-center gap-2" onSubmit={handleCodeSubmit}>
        <div className="font-karla font-bold text-2xl">{t('EnterPin')}.</div>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputType="tel"
          renderInput={(props) => (
            <input
              {...props}
              className="flex-inline py-4 px-3 box-content text-center text-xl border-1 border-gray focus:outline-none focus:border-[#3898ec]"
            />
          )}
          containerStyle="flex flex-row gap-2 align-center h-15 place-content-center"
          shouldAutoFocus={true}
        />
        <FormButton
          disabled={codeVerificationRequested}
          isLoading={codeVerificationRequested}
          type="submit"
          text={t('VerifyEmail')}
        />

        {codeError && (
          <div className="font-karla text-xl text-red text-center flex justify-center items-center border-2 rounded-md border-red max-w-96 mx-auto px-4 mb-2">
            ⚠ {t('InvalidCode')}
          </div>
        )}
      </form>
    </>
  );
}

export default VerificationStep;
